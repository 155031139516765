import { Box, Text, Icon, Flex, Image, Link, Divider } from "@chakra-ui/react";
import { Section } from "../../components/section/Section";
import { useTranslation } from "react-i18next";
import { FiMapPin, FiCalendar } from "react-icons/fi";
import { theme } from "../../utils/theme";
import img from "../../assets/images/goofy.webp";
import bgImg from "../../assets/images/green-girlands.webp";

const { colors, fonts, icons, buttons } = theme;
const { sectionFontSize, sectionNumberFontSize, headerFontSize } = fonts;
const { sectionIconStyle } = icons;
const { mapButton } = buttons;

export const Reception = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section
        parentFlexProps={{
          marginBottom: "80px",
          id: "reception",
          backgroundColor: "rgba(0,0,0,0)",
        }}
        childFlexProps={{
          width: "90vw",
          paddingTop: "20px",
          paddingBottom: "60px",
          borderStyle: "solid",
          borderColor: `${colors.mintMain}88`,
          borderWidth: "1px",
          marginBottom: "30px",
          backgroundImage: `url(${bgImg})`,
          backgroundRepeat: "repeat-x",
          backgroundColor: colors.mintBg,
        }}
      >
        <Box marginLeft="auto" transform="rotate(25deg)" marginRight="10px">
          <Text
            as="h1"
            marginTop={"40px"}
            fontSize={headerFontSize}
            fontFamily={"Autumn in November"}
            textAlign="center"
            lineHeight={"55px"}
          >
            {t("RECEPTION.HEADER")}
          </Text>
          <Divider borderColor={colors.mintMain}></Divider>
        </Box>
        <Flex
          justifyContent="center"
          alignItems={"center"}
          flexWrap={"wrap-reverse"}
          margin={{
            base: "40px 0 40px 0",
            md: "80px 0 80px 0",
          }}
        >
          <Box
            fontFamily={"Autumn in November"}
            textAlign="center"
            margin="10px 30px 0 30px"
          >
            <Icon {...sectionIconStyle} as={FiCalendar}></Icon>
            <Text fontSize={sectionFontSize}>{t("RECEPTION.DATE")}</Text>
            <Text fontSize={sectionNumberFontSize}>{t("RECEPTION.HOUR")}</Text>
            <Divider
              marginBottom={4}
              marginTop={4}
              borderColor={colors.mintMain}
            ></Divider>
            <Icon {...sectionIconStyle} as={FiMapPin}></Icon>
            <Text fontSize={sectionFontSize}>{t("RECEPTION.PLACE")}</Text>
            <Box fontSize={sectionFontSize}>
              <Text as="span" fontSize={sectionFontSize} marginRight="6px">
                {t("RECEPTION.STREET")}
              </Text>
              <Text as="span" fontSize={sectionNumberFontSize} marginLeft="6px">
                {t("RECEPTION.STREET_NUMBER")}
              </Text>
            </Box>
            <Box fontSize={sectionFontSize}>
              <Text
                as="span"
                fontSize={sectionNumberFontSize}
                marginRight="6px"
              >
                {t("RECEPTION.POSTAL")}
              </Text>
              <Text as="span" fontSize={sectionFontSize} marginLeft="6px">
                {t("RECEPTION.CITY")}
              </Text>
            </Box>
            <Link
              href="https://goo.gl/maps/Yn3pgzyqLgkqpUK46"
              {...mapButton}
              target="blank"
              textDecoration="none !important"
            >
              {t("MAP_BUTTON")}
            </Link>
          </Box>
          <Image
            width={{
              base: "15em",
              md: "20em",
              lg: "25em",
            }}
            src={img}
            borderRadius={200}
            margin="0 30px 10px 30px"
            borderStyle="solid"
            borderColor={`${colors.mintMain}88`}
            borderWidth="1px"
          ></Image>
        </Flex>
      </Section>
    </>
  );
};
