import {
  Contact,
  Details,
  Hero,
  Photos,
  Navbar,
  Rsvp,
  Wedding,
  Footer,
} from "../../modules";
import { Reception } from "../../modules/reception/Reception";
import { Box } from "@chakra-ui/react";
import { photos } from "../../modules/photos/photos-src";

export const Main = () => {
  return (
    <Box>
      <Hero></Hero>
      <Navbar></Navbar>
      <Wedding></Wedding>
      <Photos photos={photos.slice(0, 6)}></Photos>
      <Reception></Reception>
      <Photos photos={photos.slice(6, 9)}></Photos>
      <Details></Details>
      <Photos
        photos={photos.slice(9, 10)}
        width={{ base: "80%", md: "40%" }}
      ></Photos>
      <Rsvp></Rsvp>
      <Photos photos={photos.slice(10, 13)}></Photos>
      <Contact></Contact>
      <Photos photos={photos.slice(13)}></Photos>
      <Footer></Footer>
    </Box>
  );
};
