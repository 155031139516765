import { Box, Text, Heading } from "@chakra-ui/react";
import { Section } from "../../components/section/Section";
import img from "../../assets/images/heroBg.webp";
import { ScrollDown } from "../../components/scrollDown/scrollDown";

export const Hero = () => {
  return (
    <>
      <Section
        childFlexProps={{
          width: "80vw",
          height: "80vh",
        }}
        parentFlexProps={{
          id: "hero",
          height: "100vh",
          backgroundImage: `url(${img})`,
          backgroundPosition: "80% 35%",
          backgroundAttachment: "fixed",
        }}
      >
        <Box>
          <Box color="white" marginBottom={"25px"}>
            <Heading
              margin="auto"
              height="inherit"
              lineHeight="inherit"
              fontSize={{
                base: "3rem",
                md: "5rem",
                sm: "3.5rem",
                lg: "6rem",
              }}
              fontWeight="500"
              textAlign="center"
              fontFamily={"Autumn in November"}
            >
              Ala & Piotrek
            </Heading>
            <Text
              margin="auto"
              textAlign="center"
              fontSize={{
                base: "1.2rem",
                md: "2.5rem",
                sm: "2rem",
                lg: "3rem",
              }}
              fontFamily={"Autumn in November"}
            >
              08.06.2023
            </Text>
          </Box>
          <ScrollDown props={{ scrollId: "wedding" }}></ScrollDown>
        </Box>
      </Section>
    </>
  );
};
