import {
  Box,
  Text,
  Divider,
  Flex,
  Grid,
  GridItem,
  Icon,
  Link,
} from "@chakra-ui/react";
import { Section } from "../../components/section/Section";
import { theme } from "../../utils/theme";
import { useTranslation } from "react-i18next";
import { IconType } from "react-icons";
import {
  GiBus,
  GiFlowerPot,
  GiPersonInBed,
  GiAmpleDress,
} from "react-icons/gi";
import baloon from "../../assets/images/baloon.webp";
import bench from "../../assets/images/couple_bench.webp";
import baloonsRunning from "../../assets/images/baloons_running.webp";
import baloonsSeparate from "../../assets/images/baloons_separate.webp";
import baloonsSeparateClose from "../../assets/images/baloons_separate_close.webp";

const { colors, fonts, icons, buttons } = theme;
const { headerFontSize } = fonts;
const { sectionIconStyle } = icons;
const { mapButton } = buttons;

export const Details = () => {
  const { t } = useTranslation();

  const cards = t("DETAILS.INFO_CARDS", { returnObjects: true }) as {
    NAME: string;
    HEADER: string;
    DESCRIPTION: string;
  }[];

  const cardsBgs = [
    {
      backgroundImage: {
        base: `${baloonsSeparateClose}`,
        md: `${baloonsSeparate}`,
      },
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: {
        base: "50% 35%",
        md: "0% 0%",
      },
    },
    {
      backgroundImage: `${baloon}`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "150px",
      backgroundPosition: "100% 60%",
    },
    {
      backgroundImage: `${bench}`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "170px",
      backgroundPosition: "0% 98%",
    },
    {
      backgroundImage: `${baloonsRunning}`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "contain",
      backgroundPosition: "50% 100%",
    },
  ];

  const icons: Record<string, { icon: IconType; size: number }> = {
    Transport: { icon: GiBus, size: 4 },
    Accommodation: { icon: GiPersonInBed, size: 4 },
    DressCode: { icon: GiAmpleDress, size: 3 },
    Flowers: { icon: GiFlowerPot, size: 3 },
  };

  return (
    <>
      <Section
        parentFlexProps={{
          marginTop: "40px",
          id: "details",
          minHeight: "80vh",
        }}
      >
        <Box marginRight="auto" transform="rotate(335deg)">
          <Text
            as="h1"
            marginTop={"40px"}
            fontSize={headerFontSize}
            fontFamily={"Autumn in November"}
            textAlign="center"
            lineHeight={"55px"}
          >
            {t("DETAILS.HEADER")}
          </Text>
          <Divider borderColor={colors.mintMain}></Divider>
        </Box>
        <Flex
          marginTop="60px"
          width="90vw"
          justifyContent="center"
          alignItems={"center"}
          flexWrap={"wrap"}
          margin="80px 0 80px 0"
        >
          <Grid
            templateRows={{ base: "repeat(4, 1fr)", md: "repeat(2, 1fr)" }}
            templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
            gap={6}
          >
            {cards.map((card, i) => (
              <GridItem
                key={i}
                rowSpan={1}
                colSpan={1}
                backgroundColor={colors.mintBg}
                minWidth="260px"
                maxWidth={"400px"}
                padding="20px"
                borderStyle="solid"
                borderWidth="1px"
                borderColor={`${colors.mintMain}88`}
                {...cardsBgs[i]}
                minHeight="360px"
              >
                <Flex
                  flexDirection={"column"}
                  alignItems="center"
                  fontFamily={"Futura Book Font"}
                  textAlign="justify"
                >
                  <Icon
                    {...sectionIconStyle}
                    height={`${icons[card.NAME].size}em`}
                    width={`${icons[card.NAME].size}em`}
                    as={icons[card.NAME].icon}
                  ></Icon>
                  <Text
                    marginBottom={"10px"}
                    textShadow={" 0 0 1px #000"}
                    fontSize="18px"
                  >
                    {card.HEADER}
                  </Text>
                  <Text textShadow={" 0 0 1px #555"}>{card.DESCRIPTION}</Text>
                  {card.NAME === "Flowers" && (
                    <Link
                      href="https://www.raknroll.pl/chce-pomoc/wplac/"
                      target="new"
                      {...mapButton}
                    >
                      Link
                    </Link>
                  )}
                </Flex>
              </GridItem>
            ))}
          </Grid>
        </Flex>
      </Section>
    </>
  );
};
