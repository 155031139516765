import { ReactNode } from "react";
import {
  Box,
  Flex,
  HStack,
  Link,
  IconButton,
  useDisclosure,
  Stack,
  StackDivider,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import { theme } from "../../utils/theme";
import { Link as ScrollTo } from "react-scroll";
import i18n from "../../i18n";

const Links = [
  {
    scrollTo: "wedding",
    text: "NAV.WEDDING",
  },
  {
    scrollTo: "reception",
    text: "NAV.RECEPTION",
  },
  {
    scrollTo: "details",
    text: "NAV.DETAILS",
  },
  {
    scrollTo: "rsvp",
    text: "NAV.RSVP",
  },
  {
    scrollTo: "contact",
    text: "NAV.CONTACT",
  },
];

const NavLink = ({
  children,
  scrollTo,
  onClick,
}: {
  children: ReactNode;
  scrollTo: string;
  onClick?: () => void;
}) => (
  <ScrollTo to={scrollTo} smooth={true} offset={-55}>
    <Link
      fontFamily={"Futura Book Font"}
      onClick={onClick}
      px={2}
      py={1}
      rounded={"md"}
      _hover={{
        textDecoration: "none",
        color: "gray",
      }}
      href={"#"}
    >
      {children}
    </Link>
  </ScrollTo>
);

export const Navbar = () => {
  const { colors } = theme;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useTranslation();

  return (
    <>
      <Box
        position={{ base: "fixed", md: "sticky" }}
        zIndex="999"
        top="0"
        left="0"
      >
        <Flex
          h={14}
          alignItems={"center"}
          justifyContent={"center"}
          as="header"
          zIndex="1"
          flexDirection={"column"}
          backgroundColor="rgba(255, 255, 255, 0.8)"
          backdropFilter="saturate(180%) blur(5px)"
          borderBottom={{
            base: "none",
            md: `1px solid ${colors.mintMain}66`,
          }}
        >
          <IconButton
            size={"md"}
            width={"100vw"}
            icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
            aria-label={"Open Menu"}
            display={{ md: "none" }}
            onClick={isOpen ? onClose : onOpen}
            background={"none !important"}
          />
          <HStack spacing={8} alignItems={"center"}>
            <HStack
              as={"nav"}
              spacing={4}
              display={{ base: "none", md: "flex" }}
              divider={
                <StackDivider borderColor={colors.mintMain} opacity={0.3} />
              }
            >
              {Links.map((link) => (
                <NavLink key={link.scrollTo} scrollTo={link.scrollTo}>
                  {t(link.text) as ReactNode}
                </NavLink>
              ))}
            </HStack>
          </HStack>
          <Flex
            overflow="hidden"
            display={{ md: "none", base: "flex" }}
            position="absolute"
            top="56px"
            transition="height 1s"
            height={`${isOpen ? "300px" : "0px"}`}
            backgroundColor="rgba(255, 255, 255, 0.8)"
            width="100%"
            borderBottom={`1px solid ${colors.mintMain}66`}
            alignItems="center"
            justifyContent={"center"}
          >
            <Stack
              display={"flex"}
              alignItems="center"
              justifyContent={"center"}
              as={"nav"}
              spacing={4}
              divider={
                <StackDivider
                  width={"100px"}
                  marginInlineStart="auto !important"
                  marginInlineEnd="auto !important"
                  borderColor={colors.mintMain}
                  opacity={0.3}
                />
              }
            >
              {Links.map((link) => (
                <NavLink
                  key={link.scrollTo}
                  scrollTo={link.scrollTo}
                  onClick={onClose}
                >
                  {t(link.text) as ReactNode}
                </NavLink>
              ))}
            </Stack>
          </Flex>
          <Link
            color={colors.mintMain}
            fontSize={"12px"}
            position={"absolute"}
            left="calc(100% - 65px)"
            textDecoration="none"
            _hover={{
              textDecoration: "none",
            }}
            width="50px"
            height="50px"
            textAlign="center"
            lineHeight="50px"
            onClick={() => i18n.changeLanguage(t("LANG.LINK"))}
          >
            {t("LANG.SELECTOR")}
          </Link>
        </Flex>
      </Box>
    </>
  );
};
