import { Box, Flex, Text, Divider, Image } from "@chakra-ui/react";
import { Section } from "../../components/section/Section";
import { theme } from "../../utils/theme";
import { useTranslation } from "react-i18next";
import piotrekImg from "../../assets/images/piotrek.webp";
import tomekImg from "../../assets/images/tomek.webp";
import alinaImg from "../../assets/images/alina.webp";
import martaImg from "../../assets/images/marta.webp";
import { Tile } from "./modules/Tile";
import dividerImg from "../../assets/images/divider.webp";

const { colors, fonts } = theme;
const { headerFontSize } = fonts;

export const Contact = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section
        parentFlexProps={{
          id: "contact",
          marginTop: "40px",
          minHeight: "80vh",
          width: "90vw",
        }}
        childFlexProps={{
          width: "100%",
        }}
      >
        <Box marginRight="auto" transform="rotate(335deg)">
          <Text
            as="h1"
            marginTop={"40px"}
            fontSize={headerFontSize}
            fontFamily={"Autumn in November"}
            textAlign="center"
            lineHeight={"55px"}
          >
            {t("CONTACT.HEADER")}
          </Text>
          <Divider borderColor={colors.mintMain}></Divider>
        </Box>
        <Text
          fontFamily={"Futura Book Font"}
          fontSize="18px"
          marginTop={"90px"}
          textAlign="center"
          maxWidth="500px"
        >
          {t("CONTACT.TEXT")}
        </Text>
        <Flex
          flexDir="column"
          alignItems="center"
          justifyContent="center"
          marginTop={"50px"}
          minHeight={"60vh"}
          marginBottom={"40px"}
        >
          <Image
            src={dividerImg}
            width={600}
            maxWidth="90vw"
            marginBottom={"30px"}
          ></Image>

          <Flex
            flexDirection={"row"}
            alignItems="center"
            justifyContent="center"
            maxWidth="1400px"
            marginLeft="auto"
            marginRight="auto"
            textAlign="center"
            flexWrap="wrap"
          >
            <Flex
              flexWrap={"wrap-reverse"}
              maxWidth="700px"
              alignItems="center"
              justifyContent="space-around"
            >
              <Tile
                name={t("CONTACT.MARTA")}
                mobile="+48 512 243 192"
                img={martaImg}
                parentFlexProps={{
                  height: "230px",
                  width: "230px",
                  minHeight: "230px",
                  minWidth: "230px",
                }}
                subFlexProps={{
                  minHeight: "220px",
                  minWidth: "220px",
                  maxWidth: "220px",
                }}
              ></Tile>
              <Tile
                name="Alina"
                mobile="+48 696 907 908"
                img={alinaImg}
                parentFlexProps={{
                  borderColor: `#C94C4Baa`,
                  color: `#C94C4Baa`,
                }}
              ></Tile>
            </Flex>
            <Flex
              flexWrap={"wrap"}
              maxWidth="700px"
              alignItems="center"
              justifyContent="space-around"
            >
              <Tile
                name="Piotrek"
                mobile="+48 608 748 914"
                img={piotrekImg}
                parentFlexProps={{
                  borderColor: `#C94C4Baa`,
                  color: `#C94C4Baa`,
                  backgroundColor: "#C94C4b",
                }}
              ></Tile>
              <Tile
                name={t("CONTACT.TOMEK")}
                mobile="+48 512 583 862"
                img={tomekImg}
                parentFlexProps={{
                  height: "230px",
                  width: "230px",
                  minHeight: "230px",
                  minWidth: "230px",
                }}
                subFlexProps={{
                  minHeight: "220px",
                  minWidth: "220px",
                  maxWidth: "220px",
                }}
              ></Tile>
            </Flex>
          </Flex>
          <Image
            src={dividerImg}
            width={600}
            maxWidth="90vw"
            transform="rotateX(180deg)"
            marginTop={"30px"}
          ></Image>
        </Flex>
      </Section>
    </>
  );
};
