import { ChakraProvider, theme } from "@chakra-ui/react";
import { Main } from "./pages/main/Main";

export const App = () => {
  return (
    <ChakraProvider theme={theme}>
      <Main></Main>
    </ChakraProvider>
  );
};
