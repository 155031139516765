import { Flex, FlexProps } from "@chakra-ui/react";
import { ReactNode } from "react";

export const Section = ({
  children,
  parentFlexProps,
  childFlexProps,
}: {
  children: ReactNode;
  parentFlexProps?: FlexProps;
  childFlexProps?: FlexProps;
}) => {
  return (
    <>
      <Flex
        backgroundPosition="center"
        backgroundSize="cover"
        backgroundRepeat="no-repeat"
        margin="auto"
        justifyContent="center"
        alignItems="center"
        {...parentFlexProps}
      >
        <Flex
          marginLeft="auto"
          marginRight="auto"
          direction="column"
          alignItems="center"
          justifyContent="center"
          {...childFlexProps}
        >
          {children}
        </Flex>
      </Flex>
    </>
  );
};
