import { extendTheme } from "@chakra-ui/react";

export const theme = extendTheme({
  colors: {
    mintMain: "#5D9E98",
    pinkMain: "#D6B9CB",
    mintBg: "#F2FFFF",
  },
  fonts: {
    heading: "Autumn in November",
    sectionFontSize: {
      base: "1.8rem",
      md: "2.2rem",
      sm: "1.8rem",
      lg: "2.4rem",
    },
    sectionNumberFontSize: {
      base: "1.6rem",
      md: "1.6rem",
      sm: "1.6rem",
      lg: "1.8rem",
    },
    headerFontSize: {
      base: "3rem",
      md: "3.6rem",
      sm: "3rem",
      lg: "4rem",
    },
  },
  icons: {
    sectionIconStyle: {
      width: "1.7em",
      height: "1.7em",
      marginBottom: 2,
      color: "#5D9E98",
    },
  },
  buttons: {
    mapButton: {
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "0",
      borderColor: "#5D9E98",
      background: "none",
      color: "#5D9E98",
      marginTop: "15px",
      marginBottom: "15px",
      fontFamily: "Futura Book Font",
      padding: "8px 12px",
      display: "inline-block",
    },
  },
});
