import { Flex, FlexProps, Text, Link, Icon } from "@chakra-ui/react";
import { theme } from "../../../utils/theme";
import { GiPhone } from "react-icons/gi";

const { colors } = theme;
export interface TileProps {
  name: string;
  img: string;
  mobile: string;
  parentFlexProps?: FlexProps;
  subFlexProps?: FlexProps;
}

export const Tile = (props: TileProps) => {
  const { name, img, mobile, parentFlexProps, subFlexProps } = props;

  return (
    <Flex
      minWidth="250px"
      minHeight="250px"
      backgroundImage={`url(${img})`}
      backgroundSize="cover"
      borderStyle="solid"
      borderColor={`${colors.mintMain}aa`}
      borderWidth="5px"
      boxShadow="0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)"
      margin="10px"
      color={`${colors.mintMain}`}
      backgroundRepeat="no-repeat"
      {...parentFlexProps}
    >
      <Link
        fontSize={"1.3em"}
        href={`tel:${mobile}`}
        _hover={{ textDecoration: "none" }}
      >
        <Flex
          minHeight={"240px"}
          minWidth={"240px"}
          {...subFlexProps}
          backgroundColor={`${colors.mintBg}`}
          opacity={0}
          display={{
            base: "none",
            lg: "flex",
          }}
          transition="opacity 1s"
          _hover={{
            opacity: "0.6",
          }}
          alignItems="center"
          justifyContent={"center"}
          flexDirection="column"
          fontFamily={"Futura Book Font"}
          position={"absolute"}
        >
          <Icon as={GiPhone} height="2em" width="2em"></Icon>
          <Text display={{ base: "none", lg: "block" }} fontSize={"1.7em"}>
            {name}
          </Text>
          <Text display={{ base: "none", lg: "block" }} fontSize={"1.3em"}>
            {mobile}
          </Text>
        </Flex>
        <Flex
          minHeight={"240px"}
          minWidth={"240px"}
          {...subFlexProps}
          backgroundColor={`${colors.mintBg}99`}
          display={{
            base: "flex",
            lg: "none",
          }}
          position={"absolute"}
          alignItems="center"
          justifyContent={"center"}
          flexDirection="column"
          fontFamily={"Futura Book Font"}
        >
          <Text fontSize={"1.7em"}>{name}</Text>
          <Text fontSize={"1.3em"}>{mobile}</Text>
        </Flex>
      </Link>
    </Flex>
  );
};
