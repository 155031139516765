import { Box } from "@chakra-ui/react";
import { useCallback } from "react";
import { PhotoAlbum, PhotoProps } from "react-photo-album";
import { theme } from "../../utils/theme";

const { colors } = theme;

interface CustomPhotosProps {
  photos: any;
  width?: any;
}

export const Photos = (props: CustomPhotosProps) => {
  const { photos, width } = props;

  const renderPhoto = useCallback(
    ({ imageProps: { alt, style, ...rest } }: PhotoProps) => (
      <img
        alt={alt}
        style={{
          ...style,
          padding: "10px",
          maxWidth: "calc(100% - 20px)",
          boxShadow:
            "0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)",
          transition: "box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
          backgroundColor: `${colors.mintBg}`,
          borderColor: `${colors.mintMain}88`,
          borderWidth: "1px",
          borderStyle: "solid",
        }}
        {...rest}
      />
    ),
    []
  );

  return (
    <>
      <Box
        width={width ?? "80%"}
        marginLeft="auto"
        marginRight="auto"
        marginBottom={{
          base: "70px",
          md: "100px",
        }}
        marginTop={{
          base: "70px",
          md: "100px",
        }}
      >
        <PhotoAlbum
          columns={(containerWidth) => {
            if (containerWidth < 600) return 1;
            if (containerWidth < 900) return 2;
            return 3;
          }}
          spacing={35}
          layout="masonry"
          photos={photos}
          renderPhoto={renderPhoto}
        />
      </Box>
    </>
  );
};
