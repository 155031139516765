import { Box, Text } from "@chakra-ui/react";
import { theme } from "../../utils/theme";
import { Section } from "../../components/section/Section";

const { colors } = theme;

export const Footer = () => {
  return (
    <>
      <Section>
        <Box marginTop={"20px"}>
          <Text fontSize={"10px"} opacity={"0.7"} display={"inline"}>
            Piotr Połcik{" "}
          </Text>
          <Text
            fontSize={"10px"}
            display={"inline"}
            color={`${colors.mintMain}`}
          >
            ©
          </Text>
          <Text fontSize={"10px"} opacity={"0.7"} display={"inline"}>
            {" "}
            June{" "}
          </Text>
          <Text
            fontSize={"10px"}
            display={"inline"}
            color={`${colors.mintMain}`}
          >
            2023
          </Text>
        </Box>
      </Section>
    </>
  );
};
