import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import en from "./assets/locales/en.json";
import pl from "./assets/locales/pl.json";

const resources = {
  en,
  pl,
};

i18n.use(initReactI18next).init({
  resources,
  lng: "pl",
  fallbackLng: "pl",
  debug: false,
});

export default i18n;
