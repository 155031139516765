import img_cliffs from "../../assets/images/gallery/cliffs.webp";
import img_donkey from "../../assets/images/gallery/donkey.webp";
import img_dyre from "../../assets/images/gallery/dyre_selfie.webp";
import img_egypt from "../../assets/images/gallery/egypt.webp";
import img_gangsta from "../../assets/images/gallery/gangsta.webp";
import img_giant from "../../assets/images/gallery/giant.webp";
import img_greece from "../../assets/images/gallery/greece.webp";
import img_livigno from "../../assets/images/gallery/livigno.webp";
import img_sea from "../../assets/images/gallery/sea_selfie.webp";
import img_skis from "../../assets/images/gallery/skis.webp";
import img_slavic from "../../assets/images/gallery/slavic.webp";
import img_bags from "../../assets/images/gallery/sleeping_bags.webp";
import img_beauty from "../../assets/images/gallery/sleeping_beauty.webp";
import img_stag from "../../assets/images/gallery/stag.webp";
import img_tent from "../../assets/images/gallery/tent_poppy.webp";
import img_tower from "../../assets/images/gallery/tower.webp";
import img_tromso from "../../assets/images/gallery/tromso.webp";
import img_dead from "../../assets/images/gallery/wedding_dead.webp";
import img_red from "../../assets/images/gallery/wedding_red.webp";
import img_wet from "../../assets/images/gallery/wet_selfie.webp";
import img_bucol from "../../assets/images/gallery/bucol.webp";
import img_bun from "../../assets/images/gallery/bun.webp";
import img_rocks from "../../assets/images/gallery/rocks.webp";

export const photos: any[] = [
  {
    src: `${img_donkey}`,
    width: 5184,
    height: 2916,
  },
  {
    src: `${img_greece}`,
    width: 5184,
    height: 2916,
  },
  {
    src: `${img_egypt}`,
    width: 5184,
    height: 2916,
  },
  {
    src: `${img_cliffs}`,
    width: 4000,
    height: 3000,
  },
  {
    src: `${img_red}`,
    width: 5184,
    height: 2916,
  },
  {
    src: `${img_tent}`,
    width: 4000,
    height: 3000,
  },
  {
    src: `${img_gangsta}`,
    width: 2916,
    height: 5184,
  },
  {
    src: `${img_beauty}`,
    width: 3000,
    height: 4000,
  },
  {
    src: `${img_bun}`,
    width: 2600,
    height: 4624,
  },
  {
    src: `${img_dyre}`,
    width: 1458,
    height: 2592,
  },
  {
    src: `${img_bucol}`,
    width: 2600,
    height: 4624,
  },
  {
    src: `${img_tromso}`,
    width: 3000,
    height: 4000,
  },

  {
    src: `${img_skis}`,
    width: 2916,
    height: 5184,
  },
  {
    src: `${img_slavic}`,
    width: 5184,
    height: 2916,
  },
  {
    src: `${img_sea}`,
    width: 4000,
    height: 3000,
  },
  {
    src: `${img_bags}`,
    width: 3000,
    height: 4000,
  },
  {
    src: `${img_livigno}`,
    width: 5184,
    height: 2916,
  },
  {
    src: `${img_stag}`,
    width: 4000,
    height: 3000,
  },
  {
    src: `${img_tower}`,
    width: 3000,
    height: 4000,
  },
  {
    src: `${img_giant}`,
    width: 2916,
    height: 5184,
  },
  {
    src: `${img_dead}`,
    width: 3000,
    height: 4000,
  },
  {
    src: `${img_wet}`,
    width: 5184,
    height: 2916,
  },
  {
    src: `${img_rocks}`,
    width: 5184,
    height: 3880,
  },
];
