import { Box, Text, Divider, Icon, Flex, Image, Link } from "@chakra-ui/react";
import { Section } from "../../components/section/Section";
import { useTranslation } from "react-i18next";
import { FiMapPin, FiCalendar } from "react-icons/fi";
import { theme } from "../../utils/theme";
import img from "../../assets/images/proposal.webp";
import bgImage from "../../assets/images/decor.webp";

const { colors, fonts, icons, buttons } = theme;
const { sectionFontSize, sectionNumberFontSize, headerFontSize } = fonts;
const { sectionIconStyle } = icons;
const { mapButton } = buttons;

export const Wedding = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section
        parentFlexProps={{
          id: "wedding",
          minHeight: "80vh",
          backgroundColor: "rgba(0,0,0,0)",
        }}
      >
        <Box marginRight="auto" transform="rotate(335deg)">
          <Text
            as="h1"
            marginTop={"40px"}
            fontSize={headerFontSize}
            fontFamily={"Autumn in November"}
            textAlign="center"
            lineHeight={"55px"}
          >
            {t("WEDDING.HEADER")}
          </Text>
          <Divider borderColor={colors.mintMain}></Divider>
          <Text
            as="h1"
            marginTop={"25px"}
            fontSize={headerFontSize}
            fontFamily={"Autumn in November"}
            textAlign="center"
            lineHeight={"55px"}
          >
            {t("WEDDING.SECOND_HEADER")}
          </Text>
        </Box>
        <Flex
          width="90vw"
          justifyContent="center"
          alignItems={"center"}
          flexWrap={"wrap"}
          margin={{
            base: "20px 0 40px 0",
            md: "40px 0 80px 0",
          }}
        >
          <Flex
            backgroundImage={bgImage}
            backgroundPosition={"center"}
            backgroundSize={"contain"}
            backgroundRepeat={"no-repeat"}
            borderRadius={200}
            width={{
              base: "23em",
              md: "33em",
              lg: "43em",
            }}
            height={{
              base: "23em",
              md: "33em",
              lg: "43em",
            }}
          >
            <Image
              width={{
                base: "15em",
                md: "20em",
                lg: "25em",
              }}
              opacity="0.98"
              src={img}
              borderRadius={200}
              borderStyle="solid"
              borderColor={`${colors.mintMain}88`}
              borderWidth="1px"
              margin="auto"
            ></Image>
          </Flex>
          <Box
            fontFamily={"Autumn in November"}
            textAlign="center"
            margin="10px 30px 0 30px"
          >
            <Icon {...sectionIconStyle} as={FiCalendar}></Icon>
            <Text fontSize={sectionFontSize}>{t("WEDDING.DATE")}</Text>
            <Text fontSize={sectionNumberFontSize}>{t("WEDDING.HOUR")}</Text>
            <Divider
              marginBottom={4}
              marginTop={4}
              borderColor={colors.mintMain}
            ></Divider>
            <Icon {...sectionIconStyle} as={FiMapPin}></Icon>
            <Text fontSize={sectionFontSize}>{t("WEDDING.PLACE")}</Text>
            <Box fontSize={sectionFontSize}>
              <Text as="span" fontSize={sectionFontSize} marginRight="6px">
                {t("WEDDING.STREET")}
              </Text>
              <Text as="span" fontSize={sectionNumberFontSize} marginLeft="6px">
                {t("WEDDING.STREET_NUMBER")}
              </Text>
            </Box>
            <Box fontSize={sectionFontSize}>
              <Text
                as="span"
                fontSize={sectionNumberFontSize}
                marginRight="6px"
              >
                {t("WEDDING.POSTAL")}
              </Text>
              <Text as="span" fontSize={sectionFontSize} marginLeft="6px">
                {t("WEDDING.CITY")}
              </Text>
            </Box>
            <Link
              href="https://goo.gl/maps/KcNyJ3jaw2Ws2iHa6"
              {...mapButton}
              target="blank"
              textDecoration="none !important"
            >
              {t("MAP_BUTTON")}
            </Link>
          </Box>
        </Flex>
      </Section>
    </>
  );
};
