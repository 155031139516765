import { Box, keyframes, Link } from "@chakra-ui/react";
import { Link as ScrollTo } from "react-scroll";

type ScrollDownProps = {
  scrollId: string;
};

export const ScrollDown = ({ props }: { props: ScrollDownProps }) => {
  const bounce = keyframes`
  0%,
  100%,
  20%,
  50%,
  80% {
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-10px);
    -ms-transform: translateY(-10px);
    transform: translateY(-10px);
  }
  60% {
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    transform: translateY(-5px);
  }
  `;

  const { scrollId } = props;
  return (
    <Box>
      <ScrollTo to={scrollId} smooth={true} offset={-55}>
        <Link
          href="#"
          opacity={1}
          bottom="30px"
          left="50%"
          marginLeft="auto"
          marginRight="auto"
          display="block"
          width="32px"
          height="32px"
          border="2px solid #FFF"
          backgroundSize="14px auto"
          borderRadius="50%"
          zIndex="2"
          animation={`${bounce} 2s infinite 2s`}
          transition="all .2s ease-in"
          transform="scale(1)"
          _before={{
            position: "absolute",
            top: "calc(50% - 8px)",
            left: "calc(50% - 6px)",
            transform: "rotate(-45deg)",
            display: "block",
            width: "12px",
            height: "12px",
            content: `""`,
            border: "2px solid white",
            borderWidth: "0px 0 2px 2px",
          }}
        ></Link>
      </ScrollTo>
    </Box>
  );
};
