import { Box, Text, Flex, Divider, Link, Icon } from "@chakra-ui/react";
import { Section } from "../../components/section/Section";
import { useTranslation } from "react-i18next";
import { theme } from "../../utils/theme";
import bgImg from "../../assets/images/couple_grass.webp";
import { FaCopy } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";

const { colors, fonts, buttons, icons } = theme;
const { headerFontSize } = fonts;
const { mapButton } = buttons;
const { sectionIconStyle } = icons;

export const Rsvp = () => {
  const { t } = useTranslation();

  return (
    <>
      <Section
        parentFlexProps={{
          marginBottom: "80px",
          id: "rsvp",
          backgroundColor: "rgba(0,0,0,0)",
        }}
        childFlexProps={{
          width: {
            base: "90vw",
            md: "70vw",
            lg: "42vw",
          },
          paddingTop: "20px",
          paddingBottom: "60px",
          borderStyle: "solid",
          borderColor: `${colors.mintMain}88`,
          borderWidth: "1px",
          marginBottom: "30px",
          backgroundImage: `url(${bgImg})`,
          backgroundRepeat: "no-repeat",
          backgroundColor: colors.mintBg,
          backgroundSize: "contain",
          backgroundPosition: "0% 100%",
          minHeight: "430px",
        }}
      >
        <Box
          marginLeft="auto"
          transform="rotate(25deg)"
          marginRight="10px"
          marginBottom="40px"
        >
          <Text
            as="h1"
            fontSize={headerFontSize}
            fontFamily={"Autumn in November"}
            textAlign="center"
            lineHeight={"55px"}
          >
            {t("RSVP.HEADER")}
          </Text>
          <Divider borderColor={colors.mintMain}></Divider>
        </Box>
        <Flex
          alignItems={"center"}
          justifyContent="center"
          flexDirection="column"
        >
          <Flex
            alignItems={"center"}
            justifyContent="center"
            flexDirection="column"
            paddingBottom="20px"
            textShadow={" 0 0 1px #555"}
            fontFamily={"Futura Book Font"}
            textAlign="center"
          >
            <Text
              fontSize="18px"
              maxWidth={{
                base: "80%",
                md: "70%",
                lg: "60%",
              }}
            >
              {t("RSVP.TEXT")}
            </Text>
            <Text fontSize="18px">{t("RSVP.PASSWORD_MARK")}</Text>
            <Text fontSize="18px" fontStyle="italic">
              <Icon
                onClick={() => {
                  toast(t("RSVP.COPY_NOTIFICATION"));
                  navigator.clipboard.writeText(t("RSVP.PASSWORD"));
                }}
                cursor="pointer"
                {...sectionIconStyle}
                marginLeft="10px"
                as={FaCopy}
                marginBottom="0"
                width="1.2em"
                height="1.2em"
              ></Icon>
              {t("RSVP.PASSWORD")}
              <Toaster position="bottom-center"></Toaster>
            </Text>
          </Flex>
          <Link
            target={"new"}
            href="https://forms.gle/EhC4Fv1DjNGLpK6V6"
            {...mapButton}
          >
            {t("RSVP.FORM")}{" "}
          </Link>
        </Flex>
      </Section>
    </>
  );
};
